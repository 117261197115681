import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from 'Utils/ApiEndpoints';

const baseUrl = process.env.REACT_APP_BASE_URL
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth?.authData?.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }

            if (!(args.body instanceof FormData)) {
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        },
    })(args, api, extraOptions);

    if (result?.error && result?.error.status === 401) {
        window.location.href = '#/'
    }
    return result;
};

export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        getDataProfile: builder.query({
            query: () => ({
                url: `${apiEndpoints?.getProfileData}`,
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        updatePassword: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.updatePassword,
                method: "POST",
                body: data
            }),
            providesTags: ['User'],
        }),
        logout: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.logoutAccount,
                method: "POST",
                body: data
            }),
            providesTags: ['User'],
        }),
        updateAdminData: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.updateAdmin,
                method: "POST",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        updateProfile: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.updateProfileData,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        changePassword: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.updateProfilePasswordData,
                method: "POST",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
       
    })
});

export const {
    useUpdatePasswordMutation, useUpdateAdminDataMutation, useGetDataProfileQuery,useLogoutMutation,useUpdateProfileMutation,useChangePasswordMutation
} = profileApi;
