import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from 'Utils/ApiEndpoints';

const baseUrl = process.env.REACT_APP_BASE_URL
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth?.authData?.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    })(args, api, extraOptions);

    if (result?.error && result?.error.status === 401) {
        window.location.href = '/'
    }
    return result;
};
export const commonApi = createApi({
    reducerPath: 'commonApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['CommonData', 'Faq'],
    endpoints: (builder) => ({
        getCommonData: builder.query({
            query: () => ({
                url: apiEndpoints?.commonContentUrl,
                method: 'GET',
            }),
            providesTags: ['CommonData'],
        }),
        updateCommonData: builder.mutation({
            query: (commonData) => ({
                url: apiEndpoints?.commonContentUpdateUrl,
                method: "PUT",
                body: commonData
            }),
            invalidatesTags: ['CommonData'],
        }),
        getFaq: builder.query({
            query: () => ({
                url: apiEndpoints?.faqGetUrl,
                method: 'GET',
            }),
            providesTags: ['Faq'],
        }),
        addFaq: builder.mutation({
            query: (commonData) => ({
                url: apiEndpoints?.faqCommonUrl,
                method: "POST",
                body: commonData
            }),
            invalidatesTags: ['Faq'],
        }),
        updateFaq: builder.mutation({
            query: (commonData) => ({
                url: apiEndpoints?.faqCommonUrl,
                method: "PUT",
                body: commonData
            }),
            invalidatesTags: ['Faq'],
        }),
        deleteFaq: builder.mutation({
            query: (faqId) => ({
                url: `${apiEndpoints?.faqCommonUrl}`,
                method: 'DELETE',
                body :{ question_id : faqId}
            }),
            invalidatesTags: ['Faq'],
        }),
        contactUs: builder.mutation({
            query: (commonData) => ({
                url: apiEndpoints?.sendContactUs,
                method: "POST",
                body: commonData
            }),
            invalidatesTags: ['CommonData'],
        }),

    })
});

export const {
    useGetCommonDataQuery, useUpdateCommonDataMutation, useGetFaqQuery,
    useUpdateFaqMutation, useAddFaqMutation,
    useDeleteFaqMutation,useContactUsMutation,
} = commonApi;
