export const apiEndpoints = {
    signUrl: `user_and_trainer/auth/register`,
    otpVerifyUrl: `user_and_trainer/auth/verify_otp`,
    resendOtpUrl: "user_and_trainer/auth/resend_otp",
    loginUrl: `user_and_trainer/auth/login`,
    socialLoginUrl: `user_and_trainer/auth/social_login`,
    forgetUrl: 'user_and_trainer/auth/forgot_password',
    resetPasswordUrl: "user_and_trainer/auth/reset_password",
    updateProfileData: `user_and_trainer/auth/profile`,
    updateProfilePasswordData: `user_and_trainer/auth/change_password`,
    //authorized
    getProfileData: `user_and_trainer/auth/details`,
    logoutAccount: `user_and_trainer/auth/logout`,
    trainerAppointments: `trainer/appointments/active`,
    getAppointmentPendingData: `trainer/appointments/pending`,
    cancelAppoointment: `trainer/appointments/status`,
    trainerAvailablity: `trainer/my_availability`,
    updateAppoointment: `trainer/update_availability`,
    trainerProgram: `trainer/program/list`,
    trainerProgramDetails: `trainer/program/details`,
    createProgram: "trainer/program/create",
    updateProgram: "trainer/program/update",
    programCategory: `trainer/program/category/list`,
    deleteProgram: `trainer/program/delete`,
    addWeekData: `trainer/program/session/create`,
    addTrainingVideo: `trainer/program/session/add_content`,
    updateWeekData: `trainer/program/session/update`,
    deleteWeekData: `trainer/program/session/delete`,
    deleteSessionWeekData: `trainer/program/session/delete_content`,
    updateProgramSession: `trainer/program/session/update_content`,
    //users
    getTrainersData: `user/trainer_list`,
    getWorkgoutData : `user/program/list`,
    programCategoryUser: `user/program/category/list`,
    //
    sendContactUs : `user_and_trainer/common/contactus/fill`,
    faqGetUrl: "common/questions",
    changePasswordUrl: "admin/auth/change_password",
    profileUrl: "admin/auth/details",
    commonContentUrl: "common/common_content",
    commonContentUpdateUrl: "admin/common/common_content",
    faqCommonUrl: "admin/common/question",
    userGetUrl: "admin/user/list",
    userDetailsUrl: "admin/user/details",
    userStatusUrl: "admin/user/status",
    userDashboardUrl: "admin/user/dashboard",
}