import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from 'Utils/ApiEndpoints';

const baseUrl = process.env.REACT_APP_BASE_URL
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth?.authData?.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    })(args, api, extraOptions);

    if (result?.error && result?.error.status === 401) {
        window.location.href = '/'
    }
    return result;
};

export const trainerApi = createApi({
    reducerPath: 'trainerApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        getUserData: builder.query({
            query: ({ page, limit, searchData }) => ({
                url: `${apiEndpoints?.userGetUrl}?page=${page}&limit=${limit}&search_key=${searchData ?? ''}`,
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        getUserDataDetails: builder.query({
            query: (userId) => ({
                url: `${apiEndpoints?.userDetailsUrl}?user_id=${userId}`,
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        updateUserStatus: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.userStatusUrl,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        updateWeekData: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.updateWeekData,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        getAppointmentData: builder.query({
            query: () => ({
                url: `${apiEndpoints?.trainerAppointments}`,
                method: 'GET',
            }),
        }),
        getAppointmentPendingData: builder.query({
            query: () => ({
                url: `${apiEndpoints?.getAppointmentPendingData}`,
                method: 'GET',
            }),
        }),
        getProgramData: builder.query({
            query: ({ page, limit, searchData }) => ({
                url: `${apiEndpoints?.trainerProgram}?page=${page}&limit=${limit}&search_key=${searchData ?? ''}`,
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        getProgramDetailsData: builder.query({
            query: ({ prog_id }) => ({
                url: `${apiEndpoints?.trainerProgramDetails}?prog_id=${prog_id}`,
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        getAvaialablityData: builder.query({
            query: () => ({
                url: `${apiEndpoints?.trainerAvailablity}`,
                method: 'GET',
            }),
        }),
        updateCancelData: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.cancelAppoointment,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        updateAvailablityData: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.updateAppoointment,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        createProgram: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.createProgram,
                method: "POST",
                body: data
            }),
            invalidatesTags: ['User'], // Optional, check if 'User' matches your tags
        }),
        addTrainingVideo: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.addTrainingVideo,
                method: "POST",
                body: data
            }),
            invalidatesTags: ['User'], // Optional, check if 'User' matches your tags
        }),
        addWeekData: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.addWeekData,
                method: "POST",
                body: data
            }),
            invalidatesTags: ['User'], // Optional, check if 'User' matches your tags
        }),
        updateProgram: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.updateProgram,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ['User'], // Optional, check if 'User' matches your tags
        }),
        updateProgramSession: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.updateProgramSession,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ['User'], // Optional, check if 'User' matches your tags
        }),
        getProgramCategoryData: builder.query({
            query: () => ({
                url: `${apiEndpoints?.programCategory}`,
                method: 'GET',
            }),
        }),
        deleteProgramData: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.deleteProgram,
                method: "DELETE",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        deleteWeekData: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.deleteWeekData,
                method: "DELETE",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        deleteSessionWeekData: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.deleteSessionWeekData,
                method: "DELETE",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
    })
});

export const {
    useGetUserDataQuery,
    useGetUserDataDetailsQuery,
    useUpdateUserStatusMutation,
    useGetAppointmentDataQuery,
    useGetAppointmentPendingDataQuery,
    useUpdateWeekDataMutation,
    useGetProgramDataQuery,
    useGetProgramDetailsDataQuery,
    useUpdateCancelDataMutation,
    useDeleteProgramDataMutation,
    useGetAvaialablityDataQuery,
    useUpdateAvailablityDataMutation,
    useCreateProgramMutation,
    useAddTrainingVideoMutation,
    useAddWeekDataMutation,
    useUpdateProgramMutation,
    useGetProgramCategoryDataQuery,
    useDeleteWeekDataMutation,
    useDeleteSessionWeekDataMutation,
    useUpdateProgramSessionMutation
} = trainerApi;
